<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <v-card class="card">
      <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
      <v-card-title class="heading justify-center">Book Card Report</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <label>Accession Number</label>

            <v-text-field class="text" v-model="accno" persistent-hint dense outlined v-on="on"></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <label class="add-text">Series</label>
            <v-autocomplete v-model="series" placeholder="Select Series" :items="series_data" item-text="name"
              item-value="id" outlined dense class="text" :rules="[rules.required]" required></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <v-checkbox v-model="isrange" label="Range"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <br /><br />
            <v-text-field class="text" persistent-hint dense placeholder="From" type="number" outlined
              :disabled="!isrange" :readonly="!isrange" v-model="from"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <br /><br />
            <v-text-field class="text" persistent-hint dense placeholder="To" type="number" outlined :disabled="!isrange"
              :readonly="!isrange" v-model="to"></v-text-field>
          </v-col>
        </v-row>

        <div>
          <center>
            <v-btn color="success" class="mb-4" @click="dailyData1">Fetch Data</v-btn>
          </center>
        </div>
      </v-card-text>
      </div>

      <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
      <v-row justify="end" align="end">
        <v-btn v-if="Array.isArray(tabledata) && tabledata.length" @click="printElem('exceltable1')" color="success" dark
          class=" ma-4">
          Print
        </v-btn>
      </v-row>
      <div id="exceltable1">

        <div v-for="(i, index) in tabledata" :key="index">

          <div id="div1" style=" border: 3px solid black;  padding:0px;  margin-bottom:5px; margin-top:25px;">

            <center>
              <div class="row">


                <table border="1" width="100%" style="margin:10px; border-collapse:collapse;">
                  <thead>
                    <tr>
                      <td rowspan="3" align="center"><img style="margin:20px;" border="0" :src="logo_url" width="80"
                          height="80"></td>
                      <td rowspan="3" colspan="2" align="center" valign="middle">
                        <table border="0">

                          <tbody>
                            <tr>
                              <td align="center" style="color:#000000;">
                                <font size="3">{{ org_trust_name }}</font>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style="color:#000000;">
                                <font size="4">{{ org_name }}</font>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style="color:#000000;">
                                <font size="4">{{ org_address }}</font>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td align="left" height="25px" style="color:#000000;" colspan="2">
                        Record No.: <b>LIB-CIR-R-01 </b>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" height="25px" style="color:#000000;"> Revision : <b>00
                        </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;| Date : <b>15/09/2020</b></td>
                    </tr>
                    <tr>
                      <td align="left" height="25px" style="color:#000000;">Page : <b>01/01 </b> </td>
                    </tr>
                  </thead>
                </table>




              </div>

            </center>
            <div class="pt-5">
              <center><b> Book Card </b> </center>
            </div>
            <v-row class="" style="padding-left:70px; padding-top:10px;">
              <v-col>
                <b>
                  Call No:</b>
                {{ i.cno }}
              </v-col>
              <v-col>
                <b>
                  Acc. No.:</b>
                {{ i.acc_no }}
              </v-col>
            </v-row>
            <div style="padding-left:70px; padding-top:5px;">
              <b>Author:</b><span v-for="(i1, index) in i.author" :key="index">{{ i1 }}</span>
            </div>
            <div style="padding-left:70px; padding-top:5px;">
              <b>Title:</b>{{ i.title }}
            </div>
            <center>
              <table class="" border="1" align="center" cellpadding="0" cellspacing="0"
                style="border-collaspe:collaspe;width: 95%;  margin-top:30px;">
                <tbody>
                  <tr>
                    <td>
                      <strong>Date of Issue</strong>
                    </td>
                    <td>
                      <strong>Borrower's Name</strong>
                    </td>
                    <td>
                      <strong>Borrower's Sign</strong>
                    </td>
                    <td>
                      <strong>Date of Return</strong>
                    </td>
                    <td>
                      <p>
                        <strong>Sign.</strong>
                      </p>
                      <p>
                        <strong>Lib Assistant</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>.
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                  </tr>

                  <tr>
                    <td>.
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                  </tr>

                  <tr>
                    <td>.
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                    <td>
                      &nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </center>
          </div>

          <div v-if="index % 2 == 0" style="page-break-after: always;">
          </div>
        </div>

      </div>
</div>

    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { table2excel } from "../jquery.table2excel";
import VueBarcode from "vue-barcode";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    logo_url: localStorage.getItem('logo_url'),
    org_name: localStorage.getItem('org_name'),
    org_trust_name: localStorage.getItem('org_trust_name'),
    org_address: localStorage.getItem('org_address'),
    isrange: false,
    isaccno: true,

    iscno: true,
    from: 1,
    to: 100,

    loader: false,
    init_loading: false,
    selected: [],
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Reports',
        disabled: false,
        href: '/reports',
      },
      {
        text: 'Book Card Report',
        disabled: true,
        href: '',
      },
    ],
    accno: "",
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    tabledata: [],
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",

    series_data: [],

    series: "",


  }),
  computed: {},


  mounted() {
    this.onLoad();
  },
  methods: {
    printElem(divId) {
      var content = document.getElementById(divId).innerHTML;
      var mywindow = document.body.innerHTML;

      document.body.innerHTML = content;
      window.print();
      document.body.innerHTML = mywindow;
      location.reload();
      return true;
    },
    exportexcel() {
      $("#exceltable").table2excel({
        name: "Worksheet Name",
        filename: "Barcode_report", //do not include extension
        fileext: ".xls", // file extension
      });
    },
    dailyData1() {
      if (this.isrange && this.from > this.to)
        this.showSnackbar("red", "Please enter Valid range");
      if (this.isrange && (this.from == "" || this.to == ""))
        this.showSnackbar("red", "Please enter Valid range");
      else {
        this.loader = true;

        const data = {
          accno: this.accno,
          series_id: this.series,
          isrange: this.isrange,
          from: this.from,
          to: this.to,
        };
        axios
          .post("/Librarian/getserieswiseaccno", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.loader = false;
              this.tabledata = res.data.data;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
            this.loader = false;
          })
          .finally(() => { });
      }
    },
    onLoad() {
      this.init_loading = true;
      axios
        .post("/Librarian/getseriesData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.series_data = res.data.series_data;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.series = "ALL";
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.init_loading = false;
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
<style scoped>
.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
}

#div1 {
  min-height: 500px;

  background-repeat: no-repeat;
  background-size: cover;
}

.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.add-section {
  display: block;
  margin: 1rem;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}

.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}

.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}

.checkbox .box {
  margin-top: 5px;
}

.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>
